import Image from "next/image";

export function Logo() {
    return (
        <Image
            src="/aida-logo.svg"
            alt="Aida Logo"
            width={90}
            height={45}
        />
    );
}
